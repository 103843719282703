import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className='gray-bg'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="bluecolor">Why Choose Coinsclone </span>For Cryptocurrency Development Services?</h2>
              <p className="pharagraph">Coinsclone is an ideal cryptocurrency development company that comprises developers, and technicians who have helped startups to achieve their crypto goals. As a pioneer in the crypto industry, we possess high-tech expertise and experience in creating cryptocurrencies. Our development team transforms your ideologies, and specifications with cutting-edge technologies. Our developers, designers, testers, and technicians accompany you at every stage of the cryptocurrency development process.</p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/crypto-dev/why-choose-crypto-development.webp"
                alt="best crypto app development company"
                placeholder='none'
                className='mt-3 mb-3'
                width={473}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph">Coinsclone’s developers change code into real-time creation without any errors or bugs. We thoroughly analyze your needs for cryptocurrency development and then get involved in the development process. We are large enough to serve you with various cryptocurrency development services at a very affordable cost. Make the smart decision of choosing us today and add value to your business for a futuristic tomorrow. We specialize in offering,
              </p>
              <ul className='mt-3'>
                <li>Agile Software Solutions</li>
                <li>360 Degree Support</li>
                <li>100% Success Rate</li>
                <li>Tech-savvy outcomes</li>
                <li>Quality Assured Projects</li>
                <li>Potential Upgradation</li>
              </ul>
              <p className="pharagraph mt-2">Your drastic success is our business goal!!!</p>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose