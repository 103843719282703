import React from 'react'


const BenefitsOf = () => {

  return (
    <section className="customs cryptodev pt-100 mb-0">
      <div className="container">
        <div className="row">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Enlightening Benefits </span>of Cryptocurrency Development</h2>
            <p className='text-center'>Coinsclone offers you world-class cryptocurrency development services that benefit your crypto business. Our perks of developing cryptocurrencies have been enlisted.</p>
            <div className='d-flex flex-wrap justify-content-center top'>
                <div className='custom-blk'>
                    <h3>Flexible Scalability</h3>
                    <p class="pharagraph">Even after the delivery of your cryptocurrency, our development team promises to optimize the project as per your business’s scalability and growth. We offer you the best-ever scalable options for growing startups.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Affordable Pricing</h3>  
                    <p class="pharagraph">Our development team offers you enhanced and qualified cryptocurrency development services at an affordable cost. We provide different pricing tags for various cryptocurrency development services.</p>
                </div>
                <div className='custom-blk'> 
                    <h3>On-time Delivery</h3>
                    <p class="pharagraph">With utmost perfection, we deliver your crypto project on the mentioned time without any delays. We find ways to complete your cryptocurrency development projects as soon as possible.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Best collaborators</h3>
                    <p class="pharagraph">Our developers make sure to collaborate with you in a friendly way to complete the cryptocurrency development process smoothly. We also offer consistent support even after the completion of the crypto project.</p>
                </div>
                <div className='custom-blk'>
                    <h3>High-Security</h3>
                    <p class="pharagraph">Our technical team focuses mainly on integrating security mechanisms for all the cryptocurrency development services. We authorize the APIs before integrating, and robust fraud-detecting mechanisms.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Regulatory Compliance</h3>
                    <p class="pharagraph">Our legal and regulatory team knows the nooks and corners of the legal aspects of selling cryptocurrencies. Before the development stage, we analyze the location and region to conduct the cryptocurrency sale.</p>
                </div>
            </div>
        </div> 
      </div>
    </section>
  )
}

export default BenefitsOf