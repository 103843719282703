import React from "react"

class HowtoGet extends React.Component {

  render() {
    return (

      <section className="icon whitelabel-use cryptodev pt-100 secureof">
        <div className="gray-bg">
        <div className="container">
          <h2 className="main-heading"><span className='bluecolor'>Types of </span>Cryptocurrency Development Of Coinsclone</h2>
          <p className='text-center mw1030'>Our cryptocurrency development team offers various types of cryptocurrency development that satisfy your business specifications.</p>
          <div className="row ">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                Utility Tokens
                </h3>
                <p className="pharagraph">We offer to create utility tokens that provide access to a product or a service within the cryptocurrency ecosystem. We utilize various blockchain networks like Ethereum, Solana, Tron, etc. The utility token holders will get access to a specific platform, gift cards, discounts, and voting rights. These tokens can be distributed through crypto crowdfunding methods like ICO, STO, IDO, IEO, etc.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Security Tokens
                </h3>
                <p className="pharagraph">Our security tokens are backed up with real-world assets like stocks, bonds, commodities, and real estate. We provide fractional ownership, potential dividends, and revenue sharing for security token users. Our development team adheres to offering high-end security features for the crypto tokens. Security tokens streamline the digitized trading of cryptocurrencies with traditional financial systems-protected protocols.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Altcoins
                </h3>
                <p className="pharagraph mb-lg-0">Our development team is an expert in offering Altcoin development services that serve specific use cases within the ecosystem. We develop the altcoin cryptocurrency with enhanced transaction speed and scalability. As per your business needs, we create the altcoins from an existing blockchain or create a new blockchain. We create altcoins with technological advancements and regulatory aspects.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Stablecoins
                </h3>
                <p className="pharagraph mb-lg-0">We create high-tech stablecoins that are designed to minimize price volatility by pegging their value to external assets. Our development team excels in creating stablecoins that are categorized into centralized and backed by reserves held by a trusted entity. We also create decentralized stablecoins that are collateralized by other cryptocurrencies. We develop stablecoins that facilitate cross-border payments, remittances and all kinds of crypto exchanges.</p>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>

    )
  }
}

export default HowtoGet
