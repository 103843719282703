import React from "react"

class DevProcess extends React.Component {

  render() {
    return (

      <section className="fine-box cryptodev pt-100 mb-0">
          <div className="container"> 
            <h2 className="heading-h2 text-center">Our Cryptocurrency <span className="bluecolor">Development process</span></h2>
            <p className="text-center mb-2">
            Our cryptocurrency development process benefits you with goodness till the last drop. We have formulated the cryptocurrency creation process
            </p>
            <div className="d-lg-flex flex-wrap add-one px-0">
              <div className="square" >
                <h3>Conceptualization</h3>
                <p className="pharagraph">
                We help you turn your blockchain sensation into innovation by framing the roadmap, ideologies, and methodologies for cryptocurrency development. Our expert team formulates the legal aspects, norms, and other cryptocurrency development resources at the initial stage.
                </p>
              </div>
              <div className="square" >
                <h3>Market Research</h3>
                <p className="pharagraph">
                Our expert team digs deeper into the crypto market to make sure about the competitors, necessary needs of the crypto users, etc. Then, we streamline the roadmap for the development without any hassles.
                </p>
              </div>
              <div className="square" >
                <h3>Technical Designing</h3>
                <p className="pharagraph">
                We brainstorm the latest design ideas with our technician team to lay the stones for success and innovation. A design isn’t completed until it reaches its better version. Hence, our UI team assists you in discovering user-friendly interfaces for cryptocurrency development.
                </p>
              </div>
              <div className="square" >
                <h3>Development</h3>
                <p className="pharagraph">
                Our development team takes responsibility for developing your cryptocurrency from scratch to turn your vision into values. We also empower your cryptocurrency with exponential features and plugins.
                </p>
              </div>
              <div className="square" >
                <h3>Quality Assurance</h3>
                <p className="pharagraph">
                We ensure to offer you the potential cryptocurrency without any technical errors, or bugs before deployment. After multiple tiers of testing, you can launch your cryptocurrencies for your business purposes.
                </p>
              </div>
              <div className="square" >
                <h3>Maintenance</h3>
                <p className="pharagraph">
                Own your cryptocurrency with the latest and upgraded versions. Our developers provide continuous maintenance and support until the launch and even after that. We also help you to integrate additional features if needed for your cryptocurrency.
                </p>
              </div>
            </div>
          </div>
      </section>
    )
  }
}

export default DevProcess
