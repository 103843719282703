import React from 'react';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import BannerCrypto from '../components/crypto-dev/Banner';
import FaqSection from '../components/crypto-dev/FaqSection';
import TechStack from '../components/crypto-dev/TechStack';
import UserFriendly from '../components/Cryptoexchange/UserFriendly';
import WeProvide from '../components/crypto-dev/WeProvide';
import HowtoGet from '../components/crypto-dev/HowtoGet';
import BenefitsOf from '../components/crypto-dev/BenefitsOf';
import DevProcess from '../components/crypto-dev/DevProcess';
import WhyChoose from '../components/crypto-dev/WhyChoose';

const CryptoDev = () => (
  <section className="cryptoex">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/cryptocurrency-development-company/" />
        <title>Cryptocurrency Development Company</title>
        <meta name="description" content="Coinsclone, a pioneer cryptocurrency development company helps startups in creating various kinds of crypto coins, tokens, and NFTs for cryptocurrency purposes." />
        <meta name="keywords" content="Cryptocurrency Development Company" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Cryptocurrency Development Company" />
        <meta property="og:description" content="Coinsclone, a pioneer cryptocurrency development company helps startups in creating various kinds of crypto coins, tokens, and NFTs for cryptocurrency purposes." />
        <meta property="og:url" content="https://www.coinsclone.com/cryptocurrency-development-company/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/crypto-dev/cryptocurrency-development-company.webp" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone, a pioneer cryptocurrency development company helps startups in creating various kinds of crypto coins, tokens, and NFTs for cryptocurrency purposes." />
        <meta name="twitter:title" content="Cryptocurrency Development Company" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/crypto-dev/cryptocurrency-development-company.webp" />
      </Helmet>
      
      <BannerCrypto />
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | Crytocurrency Development Company</span>
        </div>
      </div>
      <WeProvide />
      <HowtoGet />
      <BenefitsOf />
      <WhyChoose />
      <DevProcess />
      <TechStack />
      <FaqSection />
      <UserFriendly /> 
    </Layout>
  </section>
)

export default CryptoDev
